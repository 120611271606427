import React from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Program } from "../../types/program";
import ProgramsService from "../../services/programsService";
import CreateEditProgramForm from "./CreateEditProgramForm";
import useToaster from "../../hooks/useToaster";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
function EditProgram() {
  const navigate = useNavigate();
  const { showToast } = useToaster();
  const { state, search } = useLocation();

  function updateProgram(newProgram: Program, id: number) {
    ProgramsService.update({ ...newProgram, id })
      .then(() => showToast("Program successfully updated!", "success"))
      .then(() => navigate(`/private/programs${search}`))
      .catch((err) => showToast(err.message));
  }

  function cancel() {
    navigate(-1);
  }

  return (
    <>
      <Helmet title="Edit Program" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Program
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/programs">
          Programs
        </Link>
        <Typography>Edit</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <CreateEditProgramForm
                mode="update"
                programId={state.program?.id}
                onSubmit={(program, programId) =>
                  updateProgram(program, programId!)
                }
                onCancel={cancel}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default EditProgram;
