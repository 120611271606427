import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  Add as AddIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import HealthQuestionnairesTable from "./HealthQuestionnairesTable";
import { Search } from "../../types/search";
import { HealthQuestionnaire } from "../../types/health-questionnaire";
import HealthQuestionnairesService from "../../services/HealthQuestionnairesService";
import { useLocation, useNavigate } from "react-router-dom";
import useToaster from "../../hooks/useToaster";
import useLoading from "../../hooks/useLoading";
import exportData from "../../utils/downloadFile";
import SmallButton from "../../components/SmallButton";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);
const Divider = styled(MuiDivider)(spacing);

function HealthQuestionnaires() {
  const navigate = useNavigate();
  const { showToast } = useToaster();
  const { setIsLoading } = useLoading();
  const location = useLocation();
  const [totalElements, setTotalElements] = useState<number>(0);
  const [questionnaires, setQuestionnaires] = useState<HealthQuestionnaire[]>(
    []
  );
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [questionnaireWillDoAction, setQuestionnaireWillDoAction] =
    useState<HealthQuestionnaire | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [elementsPerPage, setElementsPerPage] = useState<number>(25);
  function getQuestionnairesHandler(
    page: number = 0,
    size: number = 10,
    filter: string = "",
    search: string = "",
    sortOrder: string = "",
    sortField: string = ""
  ) {
    setTableLoading(true);
    HealthQuestionnairesService.getQuestionnaires(
      page,
      size,
      filter,
      search,
      sortOrder,
      sortField
    ).then((res: Search<HealthQuestionnaire>) => {
      setQuestionnaires(res.content);
      setTotalElements(res.totalElements);
      setTableLoading(false);
    });
  }

  function clickRemoveHandler() {
    HealthQuestionnairesService.removeQuestionnaire(
      questionnaireWillDoAction?.id!
    )
      .then(() =>
        showToast("Health Questionnaire successfully removed!", "success")
      )
      .then(() => setOpenRemoveModal(false))
      .then(() =>
        getQuestionnairesHandler(pageNumber, elementsPerPage, "", "", "", "")
      )
      .catch((err) => showToast(err.message));
  }

  function updateQuestionnairesHandler(questionnaire: HealthQuestionnaire) {
    navigate(`/private/health-questionnaires/edit${location.search}`, {
      state: { questionnaire },
    });
  }

  function removeQuestionnairesHandler(questionnaire: HealthQuestionnaire) {
    setQuestionnaireWillDoAction(questionnaire);
    setOpenRemoveModal(true);
  }

  return (
    <React.Fragment>
      <Helmet title="Health Questionnaires" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Health Questionnaires
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <SmallButton
              mr={2}
              onClick={() =>
                exportData(
                  HealthQuestionnairesService,
                  "questionnaires-filters",
                  "health-questionnaires.xlsx",
                  setIsLoading
                )
              }
            >
              <FileDownloadOutlinedIcon />
              Export
            </SmallButton>
            <MuiButton
              variant="contained"
              color="primary"
              onClick={() => navigate("/private/health-questionnaires/create")}
            >
              <AddIcon />
              New Health Questionnaire
            </MuiButton>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {questionnaires!! && (
            <HealthQuestionnairesTable
              questionnaires={questionnaires}
              count={totalElements || 0}
              loading={tableLoading}
              getQuestionnaires={getQuestionnairesHandler}
              setPageNumber={setPageNumber}
              setElementsPerPage={setElementsPerPage}
              updateQuestionnaires={(questionnaire: HealthQuestionnaire) =>
                updateQuestionnairesHandler(questionnaire)
              }
              removeQuestionnaires={(questionnaire: HealthQuestionnaire) =>
                removeQuestionnairesHandler(questionnaire)
              }
            ></HealthQuestionnairesTable>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-questionnaires-dialog-title"
      >
        <DialogTitle id="remove-questionnaires-dialog-title">
          Remove Health Questionnaire
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-questionnaires-dialog-title">
            {`Do you want to remove ID#${questionnaireWillDoAction?.id} By ${questionnaireWillDoAction?.user.firstName} ${questionnaireWillDoAction?.user.lastName}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default HealthQuestionnaires;
