import React, { useLayoutEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Backdrop,
  Box,
  Button as MuiButton,
  CardContent,
  CircularProgress,
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { GenderEnum, UserDTO } from "../../types/user";
import InputMask from "react-input-mask";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { BooleanEnum } from "../../types/health-questionnaire";
import { Company } from "../../types/company";
import CompanyAutocomplete from "../../components/autocomplete/CompanyAutocomplete";
import { format } from "date-fns";
import UsersService from "../../services/usersService";
import useToaster from "../../hooks/useToaster";

const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const StyledBackdrop = styled(Backdrop)`
  color: #376fd0;
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
type UserForm = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  birthDate: Date | null;
  company?: Company;
  gender: string;
  height: string;
  memberId: string;
  active: string;
  deviceSessionLoggingEnabled: boolean;
};

const Button = styled(MuiButton)<ButtonProps>(spacing);

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  company: Yup.object().test(
    "has-properties",
    "Company is required",
    (value) => value && Object.keys(value).length > 0
  ),
  birthDate: Yup.date().required("Required"),
  gender: Yup.string().required("Required"),
  height: Yup.string()
    .required("Required")
    .test(
      "is-height-valid",
      "Height must be between 59.1 and 86.6 inches",
      (value) => {
        const height = parseFloat(value);
        return height >= 59.1 && height <= 86.6;
      }
    ),
  active: Yup.string().required("Required"),
  deviceSessionLoggingEnabled: Yup.boolean(),
});

type CreateEditUserFormProps = {
  mode: "create" | "update";
  userId?: number;
  onSubmit: (user: UserDTO, userId?: number) => void;
  onCancel: () => void;
};

function CreateEditUserForm({
  mode,
  userId,
  onSubmit,
  onCancel,
}: CreateEditUserFormProps): JSX.Element {
  const [user, setUser] = useState<UserDTO>();
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToaster();

  useLayoutEffect(() => {
    if (userId && mode === "update") {
      setIsLoading(true);
      UsersService.getById(userId)
        .then((resp) => {
          setUser(resp);
        })
        .catch((error) => {
          console.error(error);
          showToast(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [userId, mode, showToast]);

  const initialValues: UserForm = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phone: user?.phone || "",
    birthDate: user ? new Date(user.birthDate) : null,
    company: user?.company || undefined,
    gender: user?.gender || "",
    height: user?.height?.toString() || "",
    memberId: user?.memberId?.toString() || "",
    active: user ? (user.active ? "YES" : "NO") : "YES",
    deviceSessionLoggingEnabled: user?.deviceSessionLoggingEnabled ?? false,
  };

  function handleSubmit(values: UserForm) {
    const preparedData: UserDTO = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      phone: values?.phone,
      birthDate: format(new Date(values?.birthDate!), "MM/dd/yyyy"),
      company: values?.company,
      gender: values?.gender,
      height: Number(values?.height),
      memberId: Number(values?.memberId),
      username: `${values?.firstName} ${values?.lastName}`,
      active: values?.active === "YES",
      deviceSessionLoggingEnabled: values.deviceSessionLoggingEnabled,
    } as unknown as UserDTO;

    if (mode === "create") {
      onSubmit({
        ...preparedData,
        isDefaultPassword: true,
        deleted: false,
        enabled: true,
      });
    } else {
      onSubmit({ ...user, ...preparedData }, userId);
    }
  }

  return (
    <>
      {isLoading ? (
        <StyledBackdrop open={isLoading}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
          }) => (
            <CardContent>
              <form onSubmit={handleSubmit}>
                <CompanyAutocomplete
                  id="company-select"
                  label="Company"
                  selectedOption={values.company}
                  onOptionSelect={(value) => setFieldValue("company", value)}
                  isTouched={!!touched.company}
                  error={errors.company || ""}
                />

                <TextField
                  name="firstName"
                  label="First Name"
                  value={values.firstName}
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="lastName"
                  label="Last Name"
                  value={values.lastName}
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="email"
                  label="Email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />

                <InputMask
                  mask="999 999 9999"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  maskPlaceholder=" "
                >
                  <TextField
                    label="Phone number"
                    fullWidth
                    name="phone"
                    helperText={touched.phone && errors.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    variant="outlined"
                    my={2}
                  />
                </InputMask>

                <DemoContainer components={["DatePicker"]}>
                  <Box
                    component="div"
                    my={2}
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <DatePicker
                      label="Birth Date"
                      value={values.birthDate}
                      onChange={(birthDate) =>
                        setFieldValue("birthDate", birthDate)
                      }
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          fullWidth: true,
                          error: Boolean(touched.birthDate && errors.birthDate),
                        },
                      }}
                    />
                  </Box>
                </DemoContainer>

                <FormControl
                  my={2}
                  fullWidth
                  error={Boolean(touched.gender && errors.gender)}
                >
                  <InputLabel id="gender-select-label">Gender</InputLabel>
                  <Select
                    name="gender"
                    labelId="gender-select-label"
                    id="gender-select"
                    value={values.gender}
                    label="Gender"
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue("gender", e.target.value)}
                  >
                    {Object.entries(GenderEnum).map(([enumKey, labelVal]) => (
                      <MenuItem key={enumKey} value={enumKey}>
                        {labelVal}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.gender && errors.gender && (
                    <FormHelperText error>{errors.gender}</FormHelperText>
                  )}
                </FormControl>

                <InputMask
                  mask="99"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.height}
                  maskPlaceholder=" "
                >
                  <TextField
                    name="height"
                    label="Height"
                    fullWidth
                    error={Boolean(touched.height && errors.height)}
                    helperText={touched.height && errors.height}
                    variant="outlined"
                    my={2}
                  />
                </InputMask>
                <Typography variant="subtitle2" gutterBottom>
                  Enter your Height in inches
                </Typography>

                <InputMask
                  mask="999999"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.memberId}
                  maskPlaceholder=" "
                >
                  <TextField
                    name="memberId"
                    label="Member ID"
                    fullWidth
                    error={Boolean(touched.memberId && errors.memberId)}
                    helperText={touched.memberId && errors.memberId}
                    variant="outlined"
                    my={2}
                  />
                </InputMask>

                <FormControl
                  my={2}
                  fullWidth
                  error={Boolean(touched.active && errors.active)}
                >
                  <InputLabel id="active-select-label">Active</InputLabel>
                  <Select
                    name="active"
                    labelId="active-select-label"
                    id="active-select"
                    value={values.active}
                    label="Active"
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue("active", e.target.value)}
                  >
                    {Object.entries(BooleanEnum).map(([enumKey, labelVal]) => (
                      <MenuItem key={enumKey} value={enumKey}>
                        {labelVal}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.active && errors.active && (
                    <FormHelperText error>{errors.active}</FormHelperText>
                  )}
                </FormControl>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.deviceSessionLoggingEnabled}
                      onChange={(event) =>
                        setFieldValue(
                          "deviceSessionLoggingEnabled",
                          event.target.checked
                        )
                      }
                      name="deviceSessionLoggingEnabled"
                      color="primary"
                    />
                  }
                  label="Enable Device Session Logging"
                />

                <Box
                  component="div"
                  display="flex"
                  justifyContent="flex-end"
                  mt={6}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    mt={3}
                    mr={5}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    {mode === "update" ? "Update" : "Save"}
                  </Button>
                </Box>
              </form>
            </CardContent>
          )}
        </Formik>
      )}
    </>
  );
}

export default CreateEditUserForm;
