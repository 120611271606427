import React from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import CreateEditUserForm from "./CreateEditUserForm";
import UsersService from "../../services/usersService";
import { UserDTO } from "../../types/user";
import useToaster from "../../hooks/useToaster";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function EditUser() {
  const navigate = useNavigate();
  const { showToast } = useToaster();
  const { state, search } = useLocation();

  function updateUser(newUser: UserDTO, id: number) {
    UsersService.updateUser({ ...newUser, id })
      .then(() => showToast("User successfully updated!", "success"))
      .then(() => navigate(`/private/users${search}`))
      .catch((err) => showToast(err.message));
  }

  function cancel() {
    navigate(-1);
  }

  return (
    <>
      <Helmet title="Edit User" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit User
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/users">
          Users
        </Link>
        <Typography>Edit</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <CreateEditUserForm
                mode="update"
                userId={state?.user?.id}
                onSubmit={(user, userId) => updateUser(user, userId!)}
                onCancel={cancel}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default EditUser;
