import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { GenderEnum, UserDTO } from "../../types/user";
import Table from "../../components/Table";
import { useNavigate } from "react-router-dom";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import { dateRangeOperator } from "../../utils/filters/dateField";
import { generateOptionsFromEnum } from "../../utils/generateOptionsFromEnum";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import useWindowWidth from "../../hooks/useWindowSize";
import { format } from "date-fns";

type UsersTableProps = {
  users: UserDTO[];
  count: number;
  loading: boolean;
  getUsers: (
    page: number,
    size: number,
    filter: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateUser: (user: UserDTO) => void;
  removeUser: (user: UserDTO) => void;
};

const UsersTable: React.FC<UsersTableProps> = ({
  users,
  count,
  loading,
  getUsers,
  setPageNumber,
  setElementsPerPage,
  updateUser,
  removeUser,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowWidth();
  const SCREEN_WIDTH = 2200;

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    user: UserDTO
  ) => {
    event.stopPropagation();
    handleMenuClose(user.id!);
    updateUser(user);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    user: UserDTO
  ) => {
    event.stopPropagation();
    handleMenuClose(user.id!);
    removeUser(user);
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/users/${params.row.id}/details`);
    }
  };

  const genderOptions = generateOptionsFromEnum(GenderEnum);
  const yesNoOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "User ID",
      filterOperators: [textEqualsOperator],
      width: 75,
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "createdDate",
      headerName: "Date Added",
      width: windowWidth < SCREEN_WIDTH ? 175 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [dateRangeOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return format(new Date(value), "MM-dd-yyyy hh:mm a");
      },
    },
    {
      field: "company",
      headerName: "Company Name",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name;
      },
    },
    {
      field: "username",
      headerName: "User",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueGetter: (params) =>
        `${params.row?.firstName} ${params.row?.lastName}` || "",
    },
    {
      field: "email",
      headerName: "Email Address",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "gender",
      headerName: "Gender",
      width: windowWidth < SCREEN_WIDTH ? 100 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(genderOptions)],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "height",
      headerName: "Height",
      width: windowWidth < SCREEN_WIDTH ? 100 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "birthDate",
      headerName: "Birth Date",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [dateRangeOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return format(new Date(value), "MM-dd-yyyy");
      },
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "memberId",
      headerName: "Member ID",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "active",
      headerName: "Active",
      width: windowWidth < SCREEN_WIDTH ? 100 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(yesNoOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "deviceSessionLoggingEnabled",
      headerName: "Enable Device Session Logging",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(yesNoOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const user = params.row as UserDTO;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[user.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) => handleDetailButtonClick(event, user.id!)}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[user.id!]}
              open={Boolean(anchorEl[user.id!])}
              onClose={() => handleMenuClose(user.id!)}
            >
              <MenuItem onClick={(event) => handleUpdateClick(event, user)}>
                Edit
              </MenuItem>
              <MenuItem onClick={(event) => handleRemoveClick(event, user)}>
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="users"
      rows={users}
      columns={columns}
      count={count}
      loading={loading}
      getData={getUsers}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
    />
  );
};

export default UsersTable;
