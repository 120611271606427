import React, { FC } from "react";
import { TextField, MenuItem, Typography } from "@mui/material";
import {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridFilterInputValueProps,
} from "@mui/x-data-grid-pro";
import styled from "@emotion/styled";

const CurrentFilterTypography = styled(Typography)(({ theme }) => ({
  fontSize: "10px",
  lineHeight: "16px",
  color: theme.palette.text.secondary,
}));

const IS_EMPTY_OR_NOT_EMPTY_OPTIONS = [
  { value: "empty", label: "empty" },
  { value: "not empty", label: "not empty" },
];

const IsEmptyOrNotEmptyValueSelector: FC<GridFilterInputValueProps> = (
  props
) => {
  const { item, applyValue, focusElementRef } = props;

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <>
      <CurrentFilterTypography variant="caption" display="inline">
        Value
      </CurrentFilterTypography>
      <TextField
        select
        variant="standard"
        inputRef={focusElementRef}
        value={item.value || ""}
        onChange={handleFilterChange}
        style={{ width: "100%" }}
      >
        {IS_EMPTY_OR_NOT_EMPTY_OPTIONS.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export const isEmptyOrNotEmptyOperator = {
  value: "isEmptyOrNotEmpty",
  label: "is",
  requiresFilterValue: true,
  InputComponent: IsEmptyOrNotEmptyValueSelector,
  getApplyFilterFn: (
    filterItem: GridFilterItem,
    column: GridColDef
  ): ((params: GridCellParams) => boolean) | null => {
    const { value } = filterItem;

    if (!value) return null;

    if (value === "empty") {
      return (params: GridCellParams): boolean => {
        const cellValue = params.value;
        return cellValue == null || String(cellValue).trim() === "";
      };
    } else if (value === "not empty") {
      return (params: GridCellParams): boolean => {
        const cellValue = params.value;
        return cellValue != null && String(cellValue).trim() !== "";
      };
    }

    return null;
  },
};
