import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import { UserTitles } from "./UserTitles";
import { Company } from "../../types/company";
import UsersService from "../../services/usersService";
import { GenderEnum, UserDTO } from "../../types/user";
import useLoading from "../../hooks/useLoading";
import { format } from "date-fns";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function UserDetails() {
  const { id } = useParams();
  const { setIsLoading } = useLoading();
  const [user, setUser] = useState<UserDTO>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  useEffect(() => {
    setIsLoading(true);
    getUser();
  }, []);

  function getUser() {
    UsersService.getById(Number(id)).then((res: UserDTO) => {
      setUser(res);
      setIsLoading(false);
      getKeyValuesItems(res);
    });
  }

  const titles: { [key: string]: string } = UserTitles;

  function parseValueByEnum(
    key: string,
    value: string | number | boolean | Company | Date
  ): string {
    switch (key) {
      case "company":
        return (value as Company)?.name;
      case "birthDate":
      case "createdDate":
        return format(new Date(value as string), "MM-dd-yyyy hh:mm a");
      case "gender":
        return GenderEnum[value as keyof typeof GenderEnum];
      case "active":
      case "deviceSessionLoggingEnabled":
        return (value as boolean) ? "Yes" : "No";
      default:
        return value as string;
    }
  }

  function getKeyValuesItems(user: UserDTO): void {
    const orderedKeys = [
      "id",
      "createdDate",
      "company",
      "firstName",
      "lastName",
      "email",
      "gender",
      "height",
      "birthDate",
      "phone",
      "username",
      "memberId",
      "active",
      "deviceSessionLoggingEnabled",
    ];

    const items = orderedKeys.map((k) => {
      const val = user[k as keyof UserDTO];

      return {
        key: titles[k] as string,
        value: parseValueByEnum(k, val),
      } as KeyValue;
    });

    setKeyValuesItems(items);
  }

  return (
    <>
      <Helmet title="User Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        User #{user?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/users">
          Users
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            {keyValuesItems && (
              <Card px={6} pt={6}>
                <KeyValueComponent keyValueItems={keyValuesItems} />
              </Card>
            )}
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default UserDetails;
