import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  Add as AddIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { Search } from "../../types/search";
import { useLocation, useNavigate } from "react-router-dom";
import { MeasurementDTO } from "../../types/measurement";
import MeasurementsService from "../../services/MeasurementsService";
import MeasurementsTable from "./MeasurementsTable";
import useToaster from "../../hooks/useToaster";
import useLoading from "../../hooks/useLoading";
import exportData from "../../utils/downloadFile";
import SmallButton from "../../components/SmallButton";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);
const Divider = styled(MuiDivider)(spacing);

function Measurements() {
  const { showToast } = useToaster();
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();
  const [totalElements, setTotalElements] = useState<number>(0);
  const [measurements, setMeasurements] = useState<MeasurementDTO[]>([]);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [measurementWillDoAction, setMeasurementWillDoAction] =
    useState<MeasurementDTO | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [elementsPerPage, setElementsPerPage] = useState<number>(25);

  function getMeasurementsHandler(
    page: number = 0,
    size: number = 10,
    filter: string = "",
    search: string = "",
    sortOrder: string = "",
    sortField: string = ""
  ) {
    setTableLoading(true);
    MeasurementsService.getMeasurements(
      page,
      size,
      filter,
      search,
      sortOrder,
      sortField
    ).then((res: Search<MeasurementDTO>) => {
      setMeasurements(res.content);
      setTotalElements(res.totalElements);
      setTableLoading(false);
    });
  }

  function clickRemoveHandler() {
    MeasurementsService.removeMeasurement(measurementWillDoAction?.id!)
      .then(() => showToast("Measurement successfully removed!", "success"))
      .then(() => setOpenRemoveModal(false))
      .then(() =>
        getMeasurementsHandler(pageNumber, elementsPerPage, "", "", "", "")
      )
      .catch((err) => showToast(err.message));
  }

  function updateMeasurementsHandler(measurement: MeasurementDTO) {
    navigate(`/private/measurements/edit${location.search}`, {
      state: { measurement: measurement },
    });
  }

  function removeMeasurementsHandler(measurement: MeasurementDTO) {
    setMeasurementWillDoAction(measurement);
    setOpenRemoveModal(true);
  }

  return (
    <React.Fragment>
      <Helmet title="Measurements" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Measurements
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <SmallButton
              mr={2}
              onClick={() =>
                exportData(
                  MeasurementsService,
                  "measurements-filters",
                  "measurements.xlsx",
                  setIsLoading
                )
              }
            >
              <FileDownloadOutlinedIcon />
              Export
            </SmallButton>
            <MuiButton
              variant="contained"
              color="primary"
              onClick={() => navigate("/private/measurements/create")}
            >
              <AddIcon />
              New Measurement
            </MuiButton>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {measurements!! && (
            <MeasurementsTable
              measurements={measurements}
              count={totalElements || 0}
              loading={tableLoading}
              getMeasurements={getMeasurementsHandler}
              setPageNumber={setPageNumber}
              setElementsPerPage={setElementsPerPage}
              updateMeasurement={(questionnaire: MeasurementDTO) =>
                updateMeasurementsHandler(questionnaire)
              }
              removeMeasurement={(questionnaire: MeasurementDTO) =>
                removeMeasurementsHandler(questionnaire)
              }
            ></MeasurementsTable>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-measurements-dialog-title"
      >
        <DialogTitle id="remove-measurements-dialog-title">
          Remove Measurement
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-measurements-dialog-title">
            {`Do you want to remove ID#${measurementWillDoAction?.id} By ${measurementWillDoAction?.user.firstName} ${measurementWillDoAction?.user.lastName}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Measurements;
