import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Dialog,
  DialogContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import useLoading from "../../hooks/useLoading";
import MeasurementsService from "../../services/MeasurementsService";
import { MeasurementDTO } from "../../types/measurement";
import { MeasurementsLabel } from "./measurementsLabel";
import { Program } from "../../types/program";
import { GenderEnum, UserDTO } from "../../types/user";
import { roundedNumber } from "../../utils/roundedNumber";
import { convertCamelCaseToTitle } from "../../utils/convertCamelCaseToTitle";
import { format } from "date-fns";
import ObjViewer from "../../components/ObjViewer";
import { Canvas } from "react-three-fiber";
import { calculateAge } from "../../utils/cammon.utils";
import { HealthQuestionnaire } from "../../types/health-questionnaire";
import healthQuestionnairesService from "../../services/HealthQuestionnairesService";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

type MeasurementDetailsProps = {
  id: number;
  gender: string;
  date: string;
  age: number;
  height: number;
  weight: number;
  frontImage: string;
  sideImage: string;
  program: Program;
  user: UserDTO;
  neck: number;
  elbow: number;
  wrist: number;
  chest: number;
  waist: number;
  hip: number;
  thigh: number;
  knee: number;
  calf: number;
  ankle: number;
  bodyFatPercentage: number;
  fatWeight: number;
  leanBodyMass: number;
  lbmiTofbmiRatio: number;
  bodyMassIndex: number;
  waistToHeightRatio: number;
  waistToHipRatio: number;
  recommendedCalories: number;
  recommendedProtein: number;
  recommendedWater: number;
  fitnessScore: number;
  wellnessScore: number;
  workoutScore: number;
  bodyModel: string;
  bodyModelUrl: string;
};

function MeasurementDetails() {
  const { id } = useParams();
  const { setIsLoading } = useLoading();
  const [measurement, setMeasurement] = useState<MeasurementDetailsProps>();
  const [questionnaire, setQuestionnaire] = useState<HealthQuestionnaire>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  const [open, setOpen] = useState(false);
  const [dialogImageSrc, setDialogImageSrc] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);
    getCompany();
  }, []);

  useEffect(() => {
    if (measurement) {
      getKeyValuesItems(measurement);
    }
  }, [questionnaire, measurement]);

  const handleClickOpen = (imageSrc: string) => {
    setOpen(true);
    setDialogImageSrc(imageSrc);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function parseStatusDescription(value: string | null): JSX.Element | string {
    if (!value) {
      return "";
    }
    let str = value as string;
    const prefix = "400 Bad Request: ";
    if (str.startsWith(prefix)) {
      str = str.substring(prefix.length);
    }
    str = str.trim();
    if (str.startsWith('"') && str.endsWith('"')) {
      str = str.substring(1, str.length - 1);
    }
    str = str.replace(/\\"/g, '"');
    try {
      const parsedData = JSON.parse(str);
      return (
        <div>
          {Object.entries(parsedData).map(([field, messages]) => (
            <div key={field}>
              <strong>{field}</strong> - {(messages as string[]).join(", ")}
            </div>
          ))}
        </div>
      );
    } catch (e) {
      return value as string;
    }
  }

  function getCompany() {
    MeasurementsService.getById(Number(id)).then((res: MeasurementDTO) => {
      const preparedData = {
        id: res.id,
        date: res.date,
        status: res?.status,
        statusDescription: res?.statusDescription,
        companyName: res?.user,
        user: res.user,
        email: res.user,
        gender: res.gender,
        age: calculateAge(res.user?.birthDate),
        height: res.height,
        weight: res.weight,
        neck: res.volumeParams?.neck,
        elbow: res.volumeParams?.elbowGirth,
        wrist: res.volumeParams?.wrist,
        chest: res.volumeParams?.chest,
        waist: res.volumeParams?.waist,
        hip: res.volumeParams?.highHips,
        thigh: res.volumeParams?.thigh,
        knee: res.volumeParams?.knee,
        calf: res.volumeParams?.calf,
        ankle: res.volumeParams?.ankle,
        bodyFatPercentage: res.bodyFatPercentage,
        fatWeight: res.fatWeight,
        leanBodyMass: res.leanBodyMass,
        bodyMassIndex: res.bodyMassIndex,
        recommendedCalories: res.recommendedCalories,
        recommendedProtein: res.recommendedProtein,
        recommendedWater: res.recommendedWater,
        fitnessScore: res.fitnessScore,
        wellnessScore: res.wellnessScore,
        workoutScore: res.workoutScore,
        lbmiTofbmiRatio: res.lbmiTofbmiRatio,
        waistToHeightRatio: res.waistToHeightRatio,
        waistToHipRatio: res.waistToHipRatio,
        program: res.program,
        frontImage: res.frontImage,
        sideImage: res.sideImage,
        bodyModel: res.volumeParams?.bodyModel,
        bodyModelUrl: res.volumeParams?.bodyModel,
      };
      setMeasurement(preparedData);
      if (preparedData?.user?.id) {
        healthQuestionnairesService
          .getByUserIdWithLastModifiedDate(preparedData.user?.id)
          .then((questionnaire) => {
            setQuestionnaire(questionnaire);
          });
      }
      setIsLoading(false);
    });
  }

  const titles: { [key: string]: string } = MeasurementsLabel;

  function parseValueByEnum(
    key: string,
    value: string | number | Program | UserDTO
  ): JSX.Element | string {
    switch (key) {
      case "statusDescription":
        return parseStatusDescription(value as string);
      case "user":
        return `${(value as UserDTO).firstName} ${(value as UserDTO).lastName}`;
      case "companyName":
        return (value as UserDTO)?.company?.name;
      case "email":
        return (value as UserDTO).email;
      case "gender":
        return GenderEnum[
          convertCamelCaseToTitle(value as string) as keyof typeof GenderEnum
        ];
      case "program":
        return (value as Program)?.name;
      case "date":
        return format(new Date(value as string), "MM-dd-yyyy hh:mm a");
      case "bodyFatPercentage":
        return roundedNumber(value as number, 4)?.toString() as string;
      case "status":
        return (value as string)?.toUpperCase();
      case "frontImage":
      case "sideImage":
        return (
          <img
            src={value as string}
            alt={key}
            height="200px"
            onClick={() => handleClickOpen(value as string)}
            style={{
              cursor: "pointer",
              border: "1px solid #000",
              borderRadius: "4px",
            }}
          />
        );
      case "bodyModel":
        return (
          <Canvas
            style={{
              height: 500,
              border: "1px solid #000",
              borderRadius: "4px",
            }}
          >
            <ObjViewer objPath={value as string} />
          </Canvas>
        );
      case "bodyModelUrl":
        if (value === null) {
          return "File not found";
        }
        return (
          <a download href={value as string}>
            <MuiButton variant="contained" color="primary">
              Download
            </MuiButton>
          </a>
        );
      default:
        return roundedNumber(value as number)?.toString() as string;
    }
  }

  function getKeyValuesItems(measurement: MeasurementDetailsProps): void {
    const filteredEntries = Object.entries(measurement).filter(
      ([key, value]) => {
        if (!questionnaire?.imageMeasurement) {
          return ![
            "frontImage",
            "sideImage",
            "bodyModel",
            "bodyModelUrl",
          ].includes(key);
        }
        return true;
      }
    );

    const items = filteredEntries.map(
      ([k, val]) =>
        ({
          key: titles[k] as string,
          value: parseValueByEnum(k, val),
        } as KeyValue)
    );
    setKeyValuesItems(items);
  }

  return (
    <>
      <Helmet title="Measurement Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Measurement #{measurement?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/measurements">
          Measurement
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            {keyValuesItems && (
              <Card px={6} pt={6}>
                <KeyValueComponent keyValueItems={keyValuesItems} />
              </Card>
            )}
          </Shadow>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <img src={dialogImageSrc || ""} alt="Dialog" width="100%" />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default MeasurementDetails;
