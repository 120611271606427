import React from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useToaster from "../../hooks/useToaster";
import { HealthArticle } from "../../types/health-article";
import HealthArticleService from "../../services/HealthArticleService";
import CreateEditHealthArticleForm from "./CreateEditHealthArticleForm";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
function EditHealthArticle() {
  const navigate = useNavigate();
  const { showToast } = useToaster();
  const { state, search } = useLocation();

  async function updateHealthArticle(
    newHealthArticle: HealthArticle,
    id: number
  ) {
    HealthArticleService.editHealthArticle({ ...newHealthArticle, id })
      .then(() =>
        showToast(`Health Article #${id} updated successfully.`, "success")
      )
      .then(() => navigate(`/private/health-articles${search}`))
      .catch((err) => showToast(err.message));
  }
  function cancel() {
    navigate(-1);
  }

  return (
    <>
      <Helmet title="Edit Meal" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Health Article
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/health-articles">
          Health Articles
        </Link>
        <Typography>Edit</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <CreateEditHealthArticleForm
                mode="update"
                healthArticleId={state.healthArticleId}
                onSubmit={(healthArticle, id) =>
                  updateHealthArticle(healthArticle, id!)
                }
                onCancel={cancel}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default EditHealthArticle;
