export const UserTitles = {
  id: "User ID",
  createdDate: "Date Added",
  lastName: "Last Name",
  firstName: "First Name",
  username: "User Name",
  email: "Email Address",
  phone: "Phone Number",
  birthDate: "Birth Date",
  company: "Company Name",
  gender: "Gender",
  height: "Height",
  memberId: "Member ID",
  active: "Active",
  deviceSessionLoggingEnabled: "Enable Device Session Logging",
};
