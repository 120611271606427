import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Backdrop,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import MeasurementsService from "../../services/MeasurementsService";
import { MeasurementDTO } from "../../types/measurement";
import EditMeasurementForm from "./EditMeasurementForm";
import useToaster from "../../hooks/useToaster";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const StyledBackdrop = styled(Backdrop)`
  color: #376fd0;
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;
function UpdateMeasurementPage() {
  const navigate = useNavigate();
  const { showToast } = useToaster();
  const { state, search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  if (!state?.measurement) navigate("/private/measurements");

  function editMeasurementHandler(measurement: MeasurementDTO) {
    setIsLoading(true);
    MeasurementsService.editMeasurement(measurement)
      .then(() => showToast("Measurement successfully updated!", "success"))
      .then(() => navigate(`/private/measurements${search}`))
      .catch((err) => showToast(err.message))
      .finally(() => setIsLoading(false));
  }

  function cancel() {
    navigate(-1);
  }

  return (
    <React.Fragment>
      <Helmet title="Edit Measurement" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Measurement
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/measurements">
          Measurements
        </Link>
        <Typography>Edit</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StyledBackdrop open={isLoading}>
                <CircularProgress color="inherit" />
              </StyledBackdrop>
              <EditMeasurementForm
                measurementId={state.measurement.id}
                onSubmit={(measurement) => editMeasurementHandler(measurement)}
                onCancel={cancel}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UpdateMeasurementPage;
